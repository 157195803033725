import { createApp, h } from 'vue';
import { GlobalProperties } from 'o365-vue';

let pendingPromise;
export async function handleLogin(pOptions = null) {
    if (pendingPromise) {
        return pendingPromise;
    } else {        
        pendingPromise = new Promise(async (res) => {
            let loginHandlerRef;
            let wasAuthenticated = true;
            const { default: LoginHandler } = await import('o365.vue.components.Login.App.vue');
            const { autoAnimatePlugin } = await import('auto-animate');
            const app = await createApp({
                setup() {
                    return () => h(LoginHandler, {
                        initialOptions: pOptions,
                        ref: 'loginHandler', 'onClose': async () => {
                            await app.unmount();
                            appNode.remove();
                            pendingPromise = null;
                            res(wasAuthenticated);
                        }
                    });
                },
                mounted() {
                    loginHandlerRef = this.$refs.loginHandler
                }
            });
            app.use(autoAnimatePlugin);
            app.use(GlobalProperties);

            const appNode = document.createElement('div');
            appNode.id = 'loginDialog'
            appNode.style.zIndex = '30000';
            appNode.style.width = '100%';
            appNode.style.height = '100%';
            appNode.style.position = 'fixed';
            appNode.style.top = '0';
            appNode.style.backgroundColor = 'rgba(200,200,200,0.6)';
            document.body.append(appNode);

            const loginModalHeader = document.createElement('div');
            loginModalHeader.classList.add('modal-header', 'w-100','bg-white','px-3','justify-content-between','rounded-top');
            loginModalHeader.style.maxWidth = '450px';

            const titleElement = document.createElement('h5');
            titleElement.classList.add('mt-3','h5');
            titleElement.textContent = 'Logg inn eller registrer deg';

            const backButton = document.createElement('button');
            backButton.id = 'btn-close-auth';
            backButton.classList.add('btn', 'btn-close', 'pe-2');

            backButton.addEventListener('click', function handleClick(event) {
                app.unmount();
            });

            loginModalHeader.appendChild(titleElement);
            loginModalHeader.appendChild(backButton);


            window.requestAnimationFrame(() => {
                app.mount('#loginDialog');
                const container = document.getElementById('waves');
                const loginBody = document.getElementsByClassName('login-body')[0];
                container.insertBefore(loginModalHeader, loginBody);
            });
        });

        return pendingPromise;
    }
}
